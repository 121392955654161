// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

const INPUTS = document.querySelectorAll(".category-list__filters__input, .product-filter-for-mobile__input");

if (INPUTS.length > 0) {
    INPUTS.forEach((input) => {
        input.addEventListener("change", () => {
            input.closest("form").submit();
        });
    });
}
