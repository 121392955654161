// JavaScript Document

// vendor
import jQuery from "jquery";
import "bootstrap";
import "owl.carousel";

// custom

jQuery(".qty").prop("required", true);

function product_more_posts($html) {

    let str = "action=get_product_more_posts";
    const offset = jQuery("#offset_page").val();
    if (offset != "" && offset != 0) {
        str += `&offset=${offset}`;
    }

    str += `&type=${$html}`;

    jQuery.ajax({
        url: l10n["admin_url"]["admin_URL"],
        type: "POST",
        dataType: "JSON",
        data: str,
        beforeSend: () => {
            jQuery("#loader").addClass("spinner-border");
        },
        success: (data) => {
            jQuery("#loader").removeClass("spinner-border");
            jQuery(".viewmore").show();
            const responseData = JSON.parse(JSON.stringify(data));
            if (responseData.result == "") {
                jQuery("#appenddata").html("<h4 class='not-found-title'> No posts found.</h4>");
            } else {
                if ($html == "html") {
                    if (responseData.result) {
                        jQuery("#appenddata").html(responseData.result);
                        jQuery("#appenddata").html(responseData.result).delay(1000);
                    } else {
                        jQuery("#appenddata").html("No Events found");
                    }
                } else {

                    jQuery("#appenddata").append(responseData.result);
                }

            }
            const total_post = jQuery("#appenddata").find(".property-group-block").length;
            if (responseData.total_post <= total_post) {
                jQuery(".load-more").hide();
            } else {
                jQuery(".load-more").show();
            }
            jQuery("#offset_page").val(responseData.offset);

        },
        error: (e) => {
            console.log("first function");
            console.log(`Error: ${e}`);
            console.log(l10n["admin_url"]);
            console.log(str);
        }
    });
}

/* Product load more action*/
jQuery(".viewmore").on("click", function () {
    const $html = "append";
    product_more_posts($html);
});

/* Display product on change category */

/* ===== Equal Height row ======= */
const equalheight = function (container) {
    let currentTallest = 0;
    let currentRowStart = 0;
    const rowDivs = new Array();

    jQuery(container).each(function () {
        const jQueryel = jQuery(this);
        jQuery(jQueryel).innerHeight("auto");
        const topPostion = jQueryel.offset().top;
        if (currentRowStart != topPostion) {
            for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = jQueryel.innerHeight();
            rowDivs.push(jQueryel);
        } else {
            rowDivs.push(jQueryel);
            currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);
        }
        for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].innerHeight(currentTallest);
        }
    });
};

function equal_height() {
    setTimeout(function () {
        equalheight(".blog-post .summary .title");
        equalheight(".blog-post .summary");
        equalheight(".logo-category-slider .slick-track .clients-logo-item .client-logo");
    }, 200);
}

function category_more_posts($html) {

    let str = "action=get_category_more_posts";
    const offset = jQuery("#offset").val();
    if (offset != "" && offset != 0) {
        str += `&offset=${offset}`;
    }

    str += `&type=${$html}`;

    const category_term = [jQuery("input:checkbox[name='product_category']:checked").map(function () {
        return this.value;
    }).get().join(",")];

    if (category_term != "") {
        str += `&category_term=${category_term}`;
    }
    console.log(l10n["admin_url"]["admin_URL"]);
    jQuery.ajax({
        url: l10n["admin_URL"],
        type: "POST",
        dataType: "JSON",
        data: str,
        beforeSend: () => {
            if ($html == "html") {
                jQuery("#html_loader").addClass("html_loader");
                jQuery(".categoryloadmore").hide();
            } else {
                jQuery("#loader").addClass("loader");
            }
        },
        success: (data) => {
            jQuery("#html_loader").removeClass("html_loader");
            jQuery("#loader").removeClass("loader");
            jQuery(".categoryloadmore").show();
            const responseData = JSON.parse(JSON.stringify(data));
            if (responseData.result == "") {
                jQuery("#appenddata").html("<h4 class='not-found-title'> No posts found.</h4>");
            } else {
                if ($html == "html") {
                    if (responseData.result) {
                        jQuery("#appenddata").html(responseData.result);
                    } else {
                        jQuery("#appenddata").html("No Events found");
                    }
                } else {

                    jQuery("#appenddata").append(responseData.result);
                }
            }
            jQuery("#offset").val(responseData.offset);
            const total_post = jQuery("#appenddata").find(".item").length;
            if (responseData.total_post <= total_post) {
                jQuery(".load-more").hide();
            } else {
                jQuery(".load-more").show();
            }
            equal_height();
        },
        error: (e) => {
            console.log("second function");
            console.log(`Error: ${e}`);
            console.log(l10n["admin_url"]);
            console.log(str);
        }
    });
}

jQuery(".product_category").on("change", function () {
    const $html = "html";
    jQuery("#offset").val(0);
    category_more_posts($html);
});

jQuery(".menu-toggle").on("click", function () {
    jQuery("body").toggleClass("mobile-menu-active");
});

jQuery(".product-filter-for-mobile h2").on("click", function () {
    jQuery(".product-filter-for-mobile").toggleClass("filter-active");
});

jQuery("#BannerSlider").carousel({
    interval: 5000
});

jQuery("#feature-productSlider").owlCarousel({
    loop: true,
    items: 4,
    margin: 35,
    nav: true,
    autoPlay: true,
    responsiveClass: true,
    interval: 5000,
    responsive: {
        0: {
            items: 0,
            autoplay: false,
            nav: false
        },
        992: {
            items: 3,
            autoplay: true,
            nav: true
        },
        1399: {
            items: 4,
            nav: true,
            loop: true
        }
    }
});

jQuery("#testimonials_slider").owlCarousel({
    loop: true,
    items: 1,
    nav: true,
    autoPlay: true,
    responsiveClass: true,
    interval: 5000
});
